// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("./../src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-blessings-index-js": () => import("./../src/pages/blessings/index.js" /* webpackChunkName: "component---src-pages-blessings-index-js" */),
  "component---src-templates-archives-page-js": () => import("./../src/templates/archives-page.js" /* webpackChunkName: "component---src-templates-archives-page-js" */),
  "component---src-templates-area-page-js": () => import("./../src/templates/area-page.js" /* webpackChunkName: "component---src-templates-area-page-js" */),
  "component---src-templates-blessings-page-js": () => import("./../src/templates/blessings-page.js" /* webpackChunkName: "component---src-templates-blessings-page-js" */),
  "component---src-templates-blog-area-page-js": () => import("./../src/templates/blog-area-page.js" /* webpackChunkName: "component---src-templates-blog-area-page-js" */),
  "component---src-templates-blog-blessings-page-js": () => import("./../src/templates/blog-blessings-page.js" /* webpackChunkName: "component---src-templates-blog-blessings-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-page-js": () => import("./../src/templates/blog-tag-page.js" /* webpackChunkName: "component---src-templates-blog-tag-page-js" */),
  "component---src-templates-company-page-js": () => import("./../src/templates/company-page.js" /* webpackChunkName: "component---src-templates-company-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-shrine-area-page-js": () => import("./../src/templates/shrine-area-page.js" /* webpackChunkName: "component---src-templates-shrine-area-page-js" */),
  "component---src-templates-shrine-blessings-page-js": () => import("./../src/templates/shrine-blessings-page.js" /* webpackChunkName: "component---src-templates-shrine-blessings-page-js" */),
  "component---src-templates-shrine-page-js": () => import("./../src/templates/shrine-page.js" /* webpackChunkName: "component---src-templates-shrine-page-js" */),
  "component---src-templates-shrine-post-js": () => import("./../src/templates/shrine-post.js" /* webpackChunkName: "component---src-templates-shrine-post-js" */),
  "component---src-templates-shrine-tag-page-js": () => import("./../src/templates/shrine-tag-page.js" /* webpackChunkName: "component---src-templates-shrine-tag-page-js" */),
  "component---src-templates-tag-page-js": () => import("./../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

